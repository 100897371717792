<template>
	<div class="page-container">
		<div class="header-box">
			<div class="img-wrap">
				<img class="img-url" :src="imgUrl" :style="{width:imgWidth}" />
			</div>

			<div class="model">{{ phoneMode }}</div>
			<div class="orderNo">{{ $t("S.PrintNo") }}: <span class="orderNoRed" @click="copyNumPrint">{{ orderNo }}</span></div>
			<div class="tips">{{ $t("S.PrintSceen") }}</div>
		</div>

		<div class="btn-box" id="btnBox">
			<div class="btn-submit" @click="handleCancelOrder">
				{{ $t("S.CancelOrder") }}
			</div>
			<div class="btn-submit" @click="handleSave">
				{{ $t("S.Save") }}
			</div>
		</div>

		<!-- 提示 -->
		<Loading v-show="isLoading" :message="message"></Loading>
	</div>
</template>

<script>
	import Loading from "components/loading/loading";
	import api from "api/allApi";
	export default {
		data() {
			return {
				// 判断是否是微信浏览器
				isWeiXinFlag: true,

				// Loading提示
				isLoading: false,
				message: "",

				isHT: 1, // 编辑器类型 1-汉图 2-热转印
				orderNo: "", // 订单号

				phoneMode: "", // 型号名称
				imgUrl: "", // 效果图
				pdfUrl: "", // 生产图

				id: '', // 订单ID
				mid: 1, // 材质ID
				sn: '', //设备SN

				imgWidth: '50%'
			};
		},
		components: {
			Loading
		},
		beforeCreate() {
			this.isLoading = true;
			this.message = this.$t("C.Loadingg");
		},
		created() {
			this.isHT = localStorage.getItem("isHT");
			this.imgUrl = this.$route.query.diyPic;
			this.pdfUrl = this.$route.query.diyPdf;
			this.isLoading = false;
		},
		activated() {
			this.isHT = localStorage.getItem("isHT");
			this.imgUrl = this.$route.query.diyPic;
			this.pdfUrl = this.$route.query.diyPdf;
			this.isLoading = false;
		},
		mounted() {
			this.orderNo = this.$route.query.no;
			if (this.orderNo) {
				this.getOrderInfo();
			}

			// 兼容手机大小
			var clientHeight = document.documentElement.clientHeight;
			console.log(clientHeight);
			if (clientHeight <= 560) {
				this.imgWidth = '35%';
			}
			if (clientHeight <= 625) {
				this.imgWidth = '40%';
			}
		},
		methods: {
			copyNumPrint() {
				const that = this;
				this.$copyText(that.orderNo).then(function (e) {
					that.$toast(that.$t("S.copySuccess"))
					console.log(e)
				}, function (e) {
					that.$toast(that.$t("S.copyError"))
					console.log(e)
				})
			},
			getOrderInfo() {
				this.$api
					.get(this, api.getOrder, {
						orderNo: this.orderNo
					})
					.then((res) => {
						if (res.code === 0) {
							this.phoneMode = res.data.modelName;
							this.pdfUrl = res.data.imgUrl;

							// 订单ID
							this.id = res.data.id;
							// 材质ID
							this.mid = res.data.materialId;
							// 设备SN
							this.sn = res.data.deviceSn;
						} else {
							this.$toast(res.message);
						}
					});
			},

			isWeiXin() {
				var ua = window.navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					return true;
				} else {
					return false;
				}
			},

			// 保存图片到手机
			handleSave() {
				this.$toast(this.$t("S.ScreenshotSave"));
			},

			// 订单作废
			handleCancelOrder() {
				var that = this;
				this.$api
					.get(this, api.orderExpire, {
						orderNo: this.orderNo
					})
					.then((res) => {
						if (res.code === 0) {
							this.$toast(this.$t("S.CancelOrderSuccess"));
							// 返回首页
							setTimeout(function() {
								that.$router.push({
									path: '/home',
									query: {
										sn: that.sn,
										mid: that.mid
									}
								})
							}, 1000);
						} else {
							this.$toast(this.$t("S.CancelOrderFail"));
						}
					});
			}
		}
	};
</script>

<style rel="stylesheet/stylus" lang="stylus" scoped>
	@import '~common/styles/mixin.styl';
	@import '~common/styles/variable.styl';

	.page-container {
		padding-bottom: 4rem;
		background-color: $color-font-white;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		&::-webkit-scrollbar {
			display: none;
		}

		.header-box {
			overflow-y: scroll;

			.img-wrap {
				position: relative;
				margin: 2.4rem auto 0;

				.img-url {
					width: 50%;
				}
			}

			.model {
				margin-top: 1rem;
				font-size: 20px;
				line-height: 1.7rem;
				color: $color-font-base;
				font-weight: bold;
				margin-top: 20px;
			}

			.orderNo {
				margin-top: 1rem;
				font-size: 20px;
				line-height: 1.7rem;
				color: $color-font-base;
				font-weight: bold;
				margin-top: 30px;

				.orderNoRed {
					color: red;
					font-size 45px;
					font-weight: bold;
				}
			}

			.tips {
				margin-top: 1rem;
				font-size: 20px;
				line-height: 2.4rem;
				color: #eb0e19;
				font-weight: 600;
				margin-top: 30px;
				padding: 0 10px;
			}
		}

		.btn-box {
			margin-top: 4rem;
			display: flex;
			justify-content: space-evenly;

			.btn-submit {
				position: relative;
				width: 40% !important;
				btn-normal();
				lineHeight(4.5rem);
				color: #ffffff !important;
				font-size: 1.5rem !important;
			}
		}
	}
</style>
